<template>
  <div class="statistics">
    <van-nav-bar
      title="店铺数据"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="main">
      <div class="title">
        <van-tag size="medium" @click="activeChange(0)" :class="active == 0 ? 'active' : ''">今日</van-tag>
        <van-tag size="medium" @click="activeChange(1)" :class="active == 1 ? 'active' : ''">昨日</van-tag>
        <van-tag size="medium" @click="activeChange(2)" :class="active == 2 ? 'active' : ''">近7天</van-tag>
        <van-tag size="medium" @click="activeChange(3)" :class="active == 3 ? 'active' : ''">近30天</van-tag>
        <van-tag size="medium" @click="activeChange(4)" :class="active == 4 ? 'active' : ''">本月</van-tag>
        <van-tag size="medium" @click="activeChange(5)" :class="active == 5 ? 'active' : ''">上月</van-tag>
      </div>
      <div class="shuju">
        <van-grid :column-num="3">
          <van-grid-item text="裂变排行" :to="{name: 'Liebian', query: {type: active}}">
            <template #icon>20</template>
          </van-grid-item>
          <van-grid-item text="新增会员">
            <template #icon>{{newMember}}</template>
          </van-grid-item>
          <van-grid-item text="订单数量">
            <template #icon>{{orderNum}}</template>
          </van-grid-item>
          <van-grid-item text="支付金额">
            <template #icon>{{orderPrice}}</template>
          </van-grid-item>
          <van-grid-item text="预估收益">
            <template #icon>{{earnings}}</template>
          </van-grid-item>
          <van-grid-item text="佣金收益">
            <template #icon>{{yongjin}}</template>
          </van-grid-item>
        </van-grid>
      </div>
      <div class="membersort">
        <van-search
          v-model="keywords"
          show-action
          shape="round"
          placeholder="请搜索邀请码"
          @search="onSearch"
        >
          <template #action>
            <div @click="onSearch">搜索</div>
          </template>
        </van-search>
        <div class="main">
          <van-pull-refresh style="min-height: calc(100vh - 220px);" v-model="isRefresh" success-text="刷新完成" @refresh="onRefresh">
            <div v-if="!isnodata">
              <van-cell center v-for="(item, index) in list" :key="index">
                <template #icon>
                  <van-tag class="sort_item">{{item.sort}}</van-tag>
                </template>
                <template #title>
                  <div class="list_avatar">
                    <van-image
                      :src="item.avatar"
                      width="60"
                      height="60"
                      round
                      fit="cover"
                      lazy-load
                      @click="orderList(index)"
                    >
                      <template v-slot:loading>
                        <van-loading type="spinner" size="20" />
                      </template>
                    </van-image>
                    <van-tag round color="#fffbe8" text-color="#ed6a0c" @click="copayAgentCode(item.agent_code)">{{item.agent_code}}</van-tag>
                  </div>
                </template>
                <template #default>
                  <div class="list_title" @click="orderList(index)">
                    <div class="van-ellipsis list_title_text">{{item.nickname}}</div>
                    <div class="list_title_tag">
                      <van-tag
                        size="medium"
                        color="pink"
                        v-if="item.lock_shop >= 0 && item.lock_shop == info.id"
                      >粉</van-tag>
                      <van-tag
                        size="medium"
                        color="#74baed"
                        v-if="item.lock_shop == -1"
                      >未</van-tag>
                      <van-tag
                        size="medium"
                        color="#000000"
                        v-if="item.lock_shop >= 0 && item.lock_shop != info.id"
                      >禁</van-tag>
                    </div>
                    <van-button
                      type="danger"
                      size="mini"
                      plain
                      round
                      @click.stop="showLvelList(index)"
                    >
                      设置
                    </van-button>
                  </div>
                  <div class="level_list">
                    <van-tag round color="#EDF7F2" text-color="#1989FA">{{item.check_level == 0 ? '免考核' : '需考核'}}</van-tag>
                    <van-tag round color="#FDE7EA" text-color="#F23A5E">{{item.levelname}}</van-tag>
                    <van-tag
                      size="medium"
                      color="#FFA500"
                    >个 &yen; {{item.price}}</van-tag>
                  </div>
                  <div class="level_list" v-if="(item.shengjiArr && item.shengjiArr.point && item.shengjiArr.point.money) || (item.baojiArr && item.baojiArr.point)">
                    <div style="flex: 1;">
                      <van-progress :percentage="item.progess" :pivot-text="'本月完成'+item.progess+'%'" stroke-width="10px" style="margin-top: 7px;" />
                      <div style="margin-top: 5px; font-size: 12px; color: #333">团 {{item.totalprice}}</div>
                    </div>
                    <div style="margin-left: 10px;color: #EE0D26;">
                      <span v-if="item.shengjiArr && item.shengjiArr.point && item.shengjiArr.point.money">up &yen;{{item.shengjiArr && item.shengjiArr.point && item.shengjiArr.point.money ? item.shengjiArr.point.money : 0}}</span>
                      <span v-if="item.baojiArr && item.baojiArr.point && item.baojiArr.point.money">bao &yen;{{item.baojiArr && item.baojiArr.point && item.baojiArr.point.money ? item.baojiArr.point.money : 0}}</span>
                    </div>
                  </div>
                  <div class="level_list">
                    <span class="fans_num">新增粉丝：{{item.xiaji}}</span>
                    <span class="fans_num">总粉丝：{{item.fans}}</span>
                    <span class="order_num">订单数：{{item.ordernum}}</span>
                  </div>
                </template>
              </van-cell>
              <van-divider v-if="nofinished">没有更多啦</van-divider>
            </div>
            <van-empty v-if="isnodata" description="暂无数据" />
          </van-pull-refresh>
        </div>
        <van-action-sheet
          v-model="levelshow"
          :actions="actions"
          cancel-text="取消"
          title="选择等级"
          close-on-click-action
          @cancel="onLevelCancel"
          @select="onLevelSelect"
          get-container=".membersort"
        />
        <!-- 设置不考核弹框 -->
        <van-dialog
          v-model="showsetkaohe"
          :title="setKaoheTitle" 
          show-cancel-button
          :before-close="doCheckLevel"
        >
          <div class="checeLevel">
            <van-field
              v-model="checkLevelFrom.nickname"
              label="用户昵称："
              :border="false"
              readonly
            />
            <van-field
              v-model="checkLevelFrom.money"
              label="收款金额："
            />
            <van-field
              label="收款凭证："
              :border="false"
            >
              <template #input>
                <van-uploader v-model="fileList" :max-count="1" :after-read="afterRead" />
              </template>
            </van-field>
          </div>
        </van-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant';
export default {
  name: "Statistics",
  data() {
    return {
      active: 0,
      newMember: 0, // 新增人数
      orderNum: 0, // 订单数量
      orderPrice: 0, // 交易金额
      earnings: 0, // 预估收益
      yongjin: 0, // 佣金收益
      list: [],
      keywords: '',
      isnodata: false,
      levelshow: false, // 默认隐藏等级选择弹框
      actions: [
        { name: '顶级免考核', level: -2, className: "levelTan_list" },
        { name: '特级免考核', level: -1, className: "levelTan_list" },
        { name: '顶级会员', level: 1, className: "levelTan_list" },
        { name: '特级会员', level: 6, className: "levelTan_list" },
        { name: '普通会员', level: 0, className: "levelTan_list" }
      ],
      member: {}, // 已选用户
      index: -1, // 已选用户索引
      isLoading: false,
      setTeji: false, // 是否设置中
      info: {},
      showsetkaohe: false, // 设置是否考核弹框默认隐藏
      fileList: [], // 图片上传预览
      checkLevelFrom: {
        index: -1,
        nickname: "",
        memberid: 0,
        money: "",
        'money_img': "",
        "check_level": 1,
        level: 0
      },
      setKaoheTitle: "",
      isRefresh: false, 
      noloading: false,
      nofinished: false
    }
  },
  mounted() {
    console.log("mounted");
    this.init();
  },
  methods: {
    init() {
      console.log("init")
      this.getStatistics();
      this.getinfo();
      this.getList();
    },
    onClickLeft() {
      this.$router.back();
    },
    activeChange (index) {
      this.active = index;
      this.getStatistics();
      this.getList();
    },
    getStatistics() {
      let that = null;
      that = this;
      that.$toast({
        type: 'loading',
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      });
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/home/get_statistics",
          that.$qs.stringify({
            status: that.active
          })
        )
        .then(res => {
          console.log(res);
          that.$toast.clear();
          if (res.data.code == 100000) {
            that.newMember = res.data.data.newMember; // 新增人数
            that.orderNum = res.data.data.orderNum; // 订单数量
            that.orderPrice = res.data.data.orderPrice; // 交易金额
            that.earnings = res.data.data.earnings; // 预估收益
            that.yongjin = res.data.data.yongjin; // 佣金收益
          }
        })
        .catch(err => {
          console.log(err);
          that.$toast.clear();
        })
    },
    getinfo() {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(that.$store.state.domain + "shopsmobile/user/get_info")
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            that.info = res.data.data;
            console.log(that.info);
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    getList() {
      let that = null;
      that = this;
      this.list = [];
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '搜索中...',
      });
      this.nofinished = false;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + 'shopsmobile/order/getMemberOrderSort',
          that.$qs.stringify({
            keywords: that.keywords,
            status: that.active
          })
        )
        .then(res => {
          console.log(res);
          Toast.clear();
          that.nofinished = true;
          that.isRefresh = false;
          if (res.data.code == 100000) {
            that.list = res.data.data;
            that.isnodata = false;
          }else {
            that.isnodata = true;
          }
        })
        .catch(err => {
          that.isnodata = true;
          Toast.clear();
          console.log(err);
        })
    },
    onRefresh() {
      this.nofinished = false;
      this.isnodata = false;
      this.getStatistics();
      this.getList();
    },
    onSearch() {
      console.log("搜索");
      this.getList();
    },
    onCancel() {
      console.log("取消");
    },
    orderList(index) {
      const member = this.list[index];
      this.$router.push({
        path: "/order",
        query: {
          memberid: member.id
        }
      })
    },
    // 显示设置等级弹框
    showLvelList(index) {
      this.member = this.list[index];
      this.index = index;
      this.levelshow = true;
      console.log(index);
    },
    // 取消选择等级
    onLevelCancel() {
      console.log("取消选择等级");
    },
    onLevelSelect(item) {
      console.log("选择等级", item);
      if (parseInt(this.info.is_kaohe) == 0) {
        if (this.member.level == 1) {
          if (item.level != 1) {
            this.$toast("无法修改，不可降级");
            return false;
          }
        }else if(this.member.level == 6) {
          if (item.level == 0) {
            this.$toast("无法修改，不可降级");
            return false;
          }
        }
      }

      console.log(this.member);
      if (this.member.hehuoren && this.member.hehuoren >= 0) {
        this.setLevel(item);
      }else if (parseInt(this.member.level) !== 1 && item.level == 1 && this.member.check_level == 0) {
        this.checkLevelFrom = {
          index: this.index,
          nickname: this.member.nickname,
          memberid: this.member.id,
          money: "",
          'money_img': "",
          "check_level": 1,
          level: item.level,
          name: item.name
        }
        this.setKaoheTitle = "升为顶级";
        this.showsetkaohe = true;
      }else {
        this.setLevel(item);
      }
    },
    // 执行修改等级操作
    setLevel(item) {
      // 执行修改等级操作
      let that = null;
      that = this;
      let name = '';
      if (this.member.nickname) {
        name = this.member.nickname;
      }else {
        name = '未设置昵称';
      }

      let levelnamestr = '';
      levelnamestr = item.name ? item.name : "";

      if(item.level == -2) { // 顶级免考核 需要上传收费截图
        this.checkLevelFrom = {
          index: this.index,
          nickname: this.member.nickname,
          memberid: this.member.id,
          money: "",
          'money_img': "",
          "check_level": 1,
          level: 1,
          name: item.name
        }
        this.setKaoheTitle = item.name;
        this.showsetkaohe = true;
        return false;
      }

      if (item.level == -1) {
        this.changeTeji(this.index);
        return false;
      }

      that.$dialog.confirm({
        title: '确认更改等级',
        message: '确认要将【'+name+'】设置为'+levelnamestr+'？',
      })
      .then(() => {
        // on confirm
        that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
        that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        that.$axios
          .post(
            that.$store.state.domain + "shopsmobile/member/set_level",
            that.$qs.stringify({
              level: item.level,
              memberid: that.member.id
            })
          )
          .then(res => {
            console.log(res);
            if (res.data.code == 100000) {
              that.$toast(res.data.msg ? res.data.msg : "修改成功");
              // that.list[that.index].levelname = item.name;
              // that.list[that.index].level = item.level;
              setTimeout(() => {
                that.onRefresh();
              }, 1500);
            }else {
              that.$toast(res.data.msg ? res.data.msg : "修改失败");
            }
          })
          .catch(err => {
            console.log(err);
          })
      })
      .catch(() => {
        // on cancel
      });
        
    },
    
    // 复制邀请码
    copayAgentCode(code) {
      let _that = null;
      _that = this;
      _that.$copyText(code)
      .then(
        function(e) {
          _that.$toast("邀请码复制成功");
          console.log(e);
        },
        function(e) {
          console.log(e);
        }
      );
    },
    // 特级白名单
    changeTeji(e) {
      console.log(e);
      let that = null;
      that = this;
      let id = 0;
      let status = 0;
      id = that.list[e].id;
      status = that.list[e].teji_white;
      if (!that.setTeji) {
        that.setTeji = true;
        that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
        that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        that.$axios
          .post(
            that.$store.state.domain + "shopsmobile/member/set_teji_white",
            that.$qs.stringify({
              id: id,
              status: status
            })
          )
          .then(res => {
            console.log(res);
            that.setTeji = false;
            if (res.data.code == 100000) {
              that.$toast(res.data.msg ? res.data.msg : "设置成功");
              // that.list[e]['teji_white'] = 1-status;
              setTimeout(() => {
                that.onRefresh();
              }, 1500);
            }else {
              that.$toast(res.data.msg ? res.data.msg : "设置失败");
            }
          })
          .catch(err => {
            console.log(err);
            that.setTeji = false;
          })
      }
    },
    // 设置是否考核
    showCheckLevel(index) {
      let that = null;
      that = this;
      this.fileList = [];
      that.checkLevelFrom = {
        index: index,
        memberid: that.list[index].id,
        nickname: that.list[index].nickname,
        money: "",
        'money_img': "",
        'check_level': that.list[index].check_level,
        level: that.list[index].level,
      }
      
      if (that.list[index].check_level == 1 && that.list[index].level == 1) {
        that.setKaoheTitle = "设为免审核";
        that.showsetkaohe = true;
      }else {
        that.$dialog.confirm({
          title: '是否要重新调整为考核业绩',
          showCancelButton: true,
          beforeClose: (action, done) => {
            console.log(action);
            if (action == 'confirm') {
              that.doCheckLevel(action, done);
            }
            done();
          }
        })
      }
    },
    // 上传图片
    afterRead(file) {
      let that = null;
      that = this;
      console.log(file);
      const fordata = new FormData();
      fordata.append('file',file.file);
      // fordata.append('name',file.file.name);
      fordata.append('dir','image/shopkaohe/zhuanzhang');
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true, // 是否禁止背景点击
        message: '上传中...',
        overlay: true, // 是否显示背景遮罩层
      });
      that.$axios.defaults.headers["Content-Type"] = "multipart/form-data";
      that.$axios.post(
        that.$store.state.domain + "admin/image/shangchuan",
        fordata
      ).then( response =>{
        console.log(response);
        Toast.clear();
        if (response.data && response.data.code == 100000) {
          that.checkLevelFrom['money_img'] = response.data.img_url;
          Toast("上传完成")
        }
      }).catch( error =>{
        console.log(error);
      })
    },
    // 执行考核
    doCheckLevel(action, done) {
      let that = null;
      that = this;
      
      if(action == 'confirm'){
        that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
        that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        that.$axios
          .post(
            that.$store.state.domain + "shopsmobile/member/set_check_level",
            that.$qs.stringify(that.checkLevelFrom)
          )
          .then(res => {
            console.log(res);
            that.setTeji = false;
            if (res.data.code == 100000) {
              // if (that.checkLevelFrom.name) {
              //   that.setLevel(that.checkLevelFrom);
              // }else{
              //   that.$toast(res.data.msg ? res.data.msg : "设置成功");
              // }
              // if (that.checkLevelFrom.check_level==1) {
              //   that.list[that.checkLevelFrom.index]['check_level'] = 0;
              // }else{
              //   that.list[that.checkLevelFrom.index]['check_level'] = 1;
              // }
              that.$toast(res.data.msg ? res.data.msg : "设置成功");
              setTimeout(() => {
                that.onRefresh();
              }, 1500);
              done();
            }else {
              that.$toast(res.data.msg ? res.data.msg : "设置失败");
              done(false);
            }
          })
          .catch(err => {
            console.log(err);
            // that.setTeji = false;
            done(false);
          })
      }else {
        done();
      }
    },
  },
}
</script>
<style lang="less">
  .statistics {
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    background-color: #f8f8f8;
    .main {
      overflow: hidden;
      .title {
        display: flex;
        justify-content: center;
        background-color: #fff;
        padding: 5px 0;
        .van-tag {
          margin: 8px;
          padding: 4px 8px;
          background-color: #eee;
          color: #000;
        }
        .active {
          background-color: #66B1FF;
          color: #fff;
        }
      }
      .shuju {
        margin: 10px;
        overflow: hidden;
        border-radius: 10px;
        background-color: #fff;
        .van-grid-item__icon-wrapper {
          line-height: 24px;
          font-weight: bold;
          margin-bottom: 8px;
        }
      }
      .member_order {
        margin: 10px;
        overflow: hidden;
        background-color: #fff;
        border-radius: 10px;
        .van-cell {
          line-height: 30px;
          .van-cell__title {
            flex: 1;
            text-align: left;
          }
        }
      }
    }

    .membersort {
      // 头部样式
      .van-search {
        .van-cell__value {
          flex: 1;
        }
      }
      .main {
        width: 100%;
        overflow: hidden;
        padding-top: 1px;
        .van-cell {
          .sort_item {
            padding: 5px 10px;
            margin-right: 10px;
          }
          .van-cell__title {
            padding: 0 10px 0 0;
            overflow: hidden;
            .van-image {
              display: block;
            }
            .list_avatar {
              width: 60px;
              height: 60px;
              overflow: hidden;
              position: relative;
              .van-tag {
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
              }
            }
          }
          .van-cell__value {
            flex: 1;
            text-align: left;
            .list_title {
              display: flex;
              overflow: hidden;
              .list_title_text {
                overflow: hidden;
                flex: 1;
              }
              .list_title_tag {
                margin: 0 10px;
                .van-tag {
                  margin-right: 5px;
                  &:last-of-type {
                    margin-right: 0;
                  }
                }
              }
            }
            .level_list {
              margin-top: 5px;
              display: flex;
              justify-items: center;
              .van-tag {
                padding: 1px 5px;
                margin-right: 5px;
                &:last-of-type {
                  margin-right: 0px;
                }
              }
              .fans_num, 
              .order_num {
                padding: 0px 6px;
                background-color: #ccc;
                font-size: 10px;
                color: #333;
                line-height: 20px;
                border-radius: 3px;
              }
              .fans_num {
                margin-right: 5px;
              }
            }
          }
          .van-button {
            padding: 0 10px;
            font-size: 13px;
          }
        }
        .van-cell:first-of-type {
          .sort_item {
            background-color: #f00;
          }
        }
        .van-cell:nth-of-type(2) {
          .sort_item {
            background-color: orange;
          }
        }
        .van-cell:nth-of-type(3) {
          .sort_item {
            background-color: pink;
          }
        }
      }
    }
  }
</style>